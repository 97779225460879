<template>
  <v-card>
    <v-card-title>
      {{ $t("__store_backup_setting") }}
    </v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-checkbox
          :label="$t('__store_backup_user_data')"
          disabled
          v-model="userData"
        />
        <v-combobox
          outlined
          dense
          multiple
          small-chips
          :label="$t('__store_backup_stores')"
          :items="storeList"
          item-text="name"
          item-value="id"
          required
          :rules="[requiredRule]"
          v-model="form.storeIDs"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{
        $t("__cancel")
      }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        $t("__confirm")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "BackupUserForm",
  props: {
    stores: {
      type: Array
    }
  },
  computed: {
    storeList() {
      let list = [];
      for (const store of this.stores) {
        list.push({
          name: store.data.name,
          id: store.id
        });
      }
      return list;
    }
  },
  data() {
    return {
      form: {
        storeIDs: [],
        notifyUser: true
      },
      userData: true,
      isValid: false,
      requiredRule: v => !!v.length || this.$t("__required")
    };
  },
  methods: {
    ok() {
      let storeIDs = [];
      for (const store of this.form.storeIDs) {
        storeIDs.push(store.id);
      }
      this.$emit("ok", {
        storeIDs: storeIDs,
        notifyUser: this.form.notifyUser
      });
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
