<template>
  <div class="user">
    <v-container fluid>
      <v-row align="center" justify="center" dense>
        <v-col :cols="isMobile ? 12 : 6">
          <UserProfile />
        </v-col>
        <v-col :cols="isMobile ? 12 : 6">
          <v-card elevation="0" max-width="450px" class="mt-4">
            <v-card-title>{{ $t("__modify_account") }}</v-card-title>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    class="pt-0"
                    :rules="[rules.required]"
                    :label="$t('__first_name')"
                    v-model="input.first_name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="pt-0"
                    :rules="[rules.required]"
                    :label="$t('__last_name')"
                    v-model="input.last_name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div class="d-flex align-baseline">
                <PhoneNumberInput
                  :label="$t('__phone_number')"
                  :required="true"
                  v-model="input.phone_number"
                />
                <v-icon v-if="userIsPhoneNumberVerified" dark color="green"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-else dark color="red">mdi-close-circle</v-icon>
                <v-btn
                  class="ml-3"
                  color="primary"
                  :disabled="
                    userIsPhoneNumberVerified || sendSMSVerificationWaitTime > 0
                  "
                  @click="sendSMSVerification"
                >
                  <template v-if="sendSMSVerificationWaitTime === 0">{{
                    $t("__send_sms_verification")
                  }}</template>
                  <template v-else>{{
                    $t("__resend_sms_verification_in_second").replace(
                      "%1",
                      sendSMSVerificationWaitTime.toString()
                    )
                  }}</template>
                </v-btn>
              </div>
              <div id="recaptcha-container" class="mt-8" />
              <div
                class="my-8 d-flex align-center"
                v-if="!userIsPhoneNumberVerified"
              >
                <div>{{ $t("__phone_number_verification_code") }}</div>
                <v-text-field
                  class="mx-4"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="phoneNumberVerificationCode"
                  type="number"
                />
                <v-btn
                  color="primary"
                  :disabled="
                    userIsPhoneNumberVerified ||
                      phoneNumberVerificationCode.length === 0
                  "
                  :loading="phoneNumberVerifying"
                  @click="verifyPhoneNumber"
                >
                  {{ $t("__phone_number_verify") }}
                </v-btn>
              </div>
              <div class="caption mb-2 error--text">
                {{ phoneNumberVerificationError }}
              </div>
              <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex justify-end">
                    <v-btn
                      text
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="passwordInput = {}"
                    >
                      {{ $t("__modify_password") }}
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ $t("__modify_password") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-text-field
                        :label="$t('__old_password')"
                        type="password"
                        autofocus
                        :rules="[rules.required]"
                        v-model="passwordInput.oldPassword"
                      ></v-text-field>
                      <v-text-field
                        :label="$t('__new_password')"
                        type="password"
                        :rules="[rules.required]"
                        v-model="passwordInput.newPassword"
                      ></v-text-field>

                      <v-text-field
                        :label="$t('__confirm_new_password')"
                        type="password"
                        :rules="[rules.required, rules.checkPassword]"
                        v-model="passwordInput.comfirmNewPassword"
                      ></v-text-field>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      {{ $t("__cancel") }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="updatePassword">
                      {{ $t("__confirm") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
            <v-card-actions class="pl-4 pt-0">
              <v-btn
                color="primary"
                elevation="2"
                @click="setUserData"
                :disabled="!userIsPhoneNumberVerified"
              >
                {{ $t("__confirm") }}
              </v-btn>
              <v-btn elevation="2" :to="{ name: 'Home' }">
                {{ $t("__cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <alert
      :title="$t('__verify_phone_number')"
      :color-type="'error'"
      :alert-open="!userIsPhoneNumberVerified"
      :dismissible="true"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { firebase } from "@/plugins/firebase";
import { userAPI } from "@/plugins/service";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import UserProfile from "@/components/User/UserProfileCard";

export default {
  name: "User",
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      userID: "user/uid",
      userData: "user/data",
      firebaseUser: "user/firebaseUser",
      userPhoneNumber: "user/phoneNumber", //database
      userIsPhoneNumberVerified: "user/isPhoneNumberVerified" //firebase phone is equal to database or not
    })
  },
  components: {
    UserProfile,
    PhoneNumberInput
  },
  data() {
    return {
      dialog: false,
      input: {
        first_name: "",
        last_name: "",
        phone_number: ""
      },
      passwordInput: {
        oldPassword: "",
        newPassword: "",
        comfirmNewPassword: ""
      },
      rules: {
        required: value => !!value || this.$t("__required"),
        checkPassword: value =>
          value === this.passwordInput.newPassword ||
          this.$t("__password_failed")
      },
      sendSMSVerificationWaitTime: 0,
      phoneNumberRecaptchaVerifier: null,
      phoneNumberProvider: null,
      phoneNumberVerificationID: "",
      phoneNumberVerificationCode: "",
      phoneNumberVerifying: false,
      phoneNumberVerificationError: ""
    };
  },
  methods: {
    setUserData() {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("user/getUserIDToken")
        .then(idToken => {
          return userAPI.setUser(idToken, this.userID, this.input);
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
          window.location.reload();
        })
        .catch(err => {
          console.log(this.userID, this.input);
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    updatePassword() {
      this.dialog = false;
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("user/updatePassword", {
          checkPassword: this.passwordInput.oldPassword,
          newPassword: this.passwordInput.newPassword
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        });
    },
    sendSMSVerification() {
      if (!this.phoneNumberRecaptchaVerifier)
        this.phoneNumberRecaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container"
        );
      if (!this.phoneNumberProvider)
        this.phoneNumberProvider = new firebase.auth.PhoneAuthProvider();
      this.phoneNumberProvider
        .verifyPhoneNumber(
          this.userPhoneNumber,
          this.phoneNumberRecaptchaVerifier
        )
        .then(verificationID => {
          this.phoneNumberVerificationID = verificationID;
        })
        .catch(err => {
          let errorMessage = this.parseError(err);
          const errCode = err.code;
          if (
            errCode === "auth/invalid-phone-number" ||
            errCode === "auth/missing-phone-number" ||
            errCode === "auth/unverified-email"
          ) {
            this.phoneNumberVerificationError = errorMessage;
          } else {
            this.phoneNumberVerificationError = this.$t(
              "__send_sms_verification_failed"
            );
            console.error(errorMessage, err);
          }
        });
      this.setSendSMSVerificationWaitTime(60);
    },
    verifyPhoneNumber() {
      this.phoneNumberVerifying = true;
      const authCredential = firebase.auth.PhoneAuthProvider.credential(
        this.phoneNumberVerificationID,
        this.phoneNumberVerificationCode
      );
      this.firebaseUser
        .updatePhoneNumber(authCredential)
        .then(() => {
          this.phoneNumberVerifying = false;
          window.location.reload();
        })
        .catch(err => {
          let errorMessage = this.parseError(err);
          const errCode = err.code;
          if (
            errCode === "auth/missing-verification-code" ||
            errCode === "auth/invalid-verification-code" ||
            errCode === "auth/credential-already-in-use"
          ) {
            this.phoneNumberVerificationError = errorMessage;
          } else {
            this.phoneNumberVerificationError = this.$t(
              "__verification_failed"
            );
            console.error(errorMessage, err);
          }
          this.phoneNumberVerifying = false;
        });
    },
    setSendSMSVerificationWaitTime(second) {
      this.sendSMSVerificationWaitTime = second;
      this.setCountDownSendSMSVerificationWaitTime();
    },
    setCountDownSendSMSVerificationWaitTime() {
      if (this.sendSMSVerificationWaitTime > 0) {
        setTimeout(() => {
          this.sendSMSVerificationWaitTime -= 1;
          this.setCountDownSendSMSVerificationWaitTime();
        }, 1000);
      }
    },
    parseError(err) {
      const errCode = err.code;
      const errMessage = err.message;
      let errorMessage = "";
      if (errCode) {
        let tKey = `__firebase_error_${errCode}`;
        if (errCode === "auth/credential-already-in-use") {
          tKey +=
            "_" + err.credential.providerId + "_" + err.credential.signInMethod;
        }
        errorMessage = this.$t(tKey);
        if (errorMessage === tKey) {
          if (errMessage) {
            errorMessage = errMessage;
          } else {
            errorMessage = errCode;
          }
        }
      } else if (errMessage) {
        errorMessage = errMessage;
      } else {
        errorMessage = err;
      }
      return errorMessage;
    }
  },
  watch: {
    userData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.input.first_name = val.first_name;
          this.input.last_name = val.last_name;
          this.input.phone_number = val.phone_number;
        }
      }
    }
  },
  created() {
    this.setSendSMSVerificationWaitTime(0);
  }
};
</script>
