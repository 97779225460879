<template>
  <div class="user-profile-card">
    <v-card flat max-width="500px">
      <v-card-title>
        <div class="text-h4">{{ $t("__profile") }}</div>
        <v-spacer />
        <v-btn outlined @click="toSetBackupUser" color="primary">
          {{ $t("__store_backup_setting") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <table class="data-table">
          <tr>
            <th>
              <div>{{ $t("__first_name") }}</div>
            </th>
            <td>
              <div>{{ userFirstName }}</div>
            </td>
          </tr>
          <tr>
            <th>
              <div>{{ $t("__last_name") }}</div>
            </th>
            <td>
              <div>{{ userLastName }}</div>
            </td>
          </tr>
          <tr>
            <th>
              <div>{{ $t("__email") }}</div>
            </th>
            <td>
              <div>{{ userEMail }}</div>
            </td>
          </tr>
          <tr>
            <th>
              <div>{{ $t("__phone_number") }}</div>
            </th>
            <td>
              <div>{{ userPhoneNumber }}</div>
            </td>
          </tr>
          <tr>
            <th>
              <div>{{ $t("__invitation_code") }}</div>
            </th>
            <td>
              <div>{{ invitationCode }}</div>
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="backupUserDialog" max-width="600px" persistent>
      <BackupUserForm
        :stores="stores"
        @ok="backupUserOk"
        @cancel="backupUserCancel"
      />
    </v-dialog>
    <alert
      :title="alertTitle"
      :color-type="'success'"
      :alert-open="alertShow"
      :dismissible="true"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { userAPI } from "@/plugins/service";
import BackupUserForm from "@/components/stores/BackupUserForm";

export default {
  name: "UserProfileCard",
  components: {
    BackupUserForm
  },
  computed: {
    ...mapGetters({
      userID: "user/uid",
      userFirstName: "user/firstName",
      userLastName: "user/lastName",
      userEMail: "user/email",
      userPhoneNumber: "user/phoneNumber",
      stores: "stores/list"
    })
  },
  data() {
    return {
      invitationCode: "",
      backupUserDialog: false,
      alertTitle: this.$t("__store_backup_setting_success"),
      alertShow: false
    };
  },
  methods: {
    load() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/getUserData")
        .then(() => {
          return this.$store.dispatch("user/getUserIDToken");
        })
        .then(idToken => {
          return userAPI.getInvitationCode(idToken, this.userID);
        })
        .then(result => {
          this.invitationCode = result["invitation_code_id"];
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("setIsLoading", false);
          this.$router.replace({ name: "Home" });
        });
    },
    loadStores() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("stores/getUserStores")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.replace({ name: "Home" });
        });
    },
    toSetBackupUser() {
      this.backupUserDialog = true;
    },
    backupUserOk({ storeIDs, notifyUser }) {
      this.$store.dispatch("setIsLoading", true);
      this.backupUserDialog = false;
      return this.$store
        .dispatch("user/backupUser", { storeIDs, notifyUser })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
          this.alertShow = true;
          setTimeout(() => {
            this.alertShow = false;
          }, 5000);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    backupUserCancel() {
      this.backupUserDialog = false;
    }
  },
  mounted() {
    this.load();
    this.loadStores();
  }
};
</script>

<style lang="scss">
.data-table {
  th {
    font-size: 1.6em;
    line-height: 1.6em;
    padding-right: 1em;
    text-align: left;
  }
  td {
    font-size: 1.6em;
  }
}
</style>
